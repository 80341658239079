/**
 * @copyright   Dreamover-Studio
 * @author      man.wang@dreamover-studio.com
 * @version     1.0.1
 * @date        2019-08-07 12:00:00
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {EventRoutingModule} from './event-routing.module';
import {EventComponent} from './event.component';
import {EventListComponent} from './eventList/eventList.component';
import {EventRegisterComponent} from './eventRegister/eventRegister.component';
import {ThemeModule} from '../../@theme/theme.module';

@NgModule({
    declarations: [EventComponent, EventListComponent,EventRegisterComponent],
    imports: [
        CommonModule,
        EventRoutingModule,
        ThemeModule.forRoot()
    ]
})
export class EventModule {
}
