/**
 * @copyright   Dreamover-Studio
 * @author      man.wang@dreamover-studio.com
 * @version     1.0.1
 * @date        2019-08-07 12:00:00
 */
import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'eventList',
    templateUrl: './eventList.component.html',
    styleUrls: ['./eventList.component.scss']
})
export class EventListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}