/**
 * @copyright   Dreamover-Studio
 * @author      man.wang@dreamover-studio.com
 * @version     1.0.1
 * @date        2019-08-07 12:00:00
 */
import {Component, OnInit, ViewChild, ViewEncapsulation, EventEmitter} from '@angular/core';

@Component({
    selector: 'eventRegister',
    templateUrl: './eventRegister.component.html',
    styleUrls: ['./eventRegister.component.scss']
})
export class EventRegisterComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
