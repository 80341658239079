/**
 * @copyright   Dreamover-Studio
 * @author      man.wang@dreamover-studio.com
 * @version     1.0.1
 * @date        2019-08-07 12:00:00
 */
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'event',
  template: `
    <router-outlet></router-outlet>
  `,
})
export class EventComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
