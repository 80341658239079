/**
 * @copyright   Dreamover-Studio
 * @author      man.wang@dreamover-studio.com
 * @version     1.0.1
 * @date        2019-08-07 12:00:00
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EventComponent } from './event.component';
import { EventListComponent } from './eventList/eventList.component';
import { EventRegisterComponent } from './eventRegister/eventRegister.component';
const routes: Routes = [{
    path: '',
    component: EventComponent,
    children: [ 
        {
            path: 'list',
            component: EventListComponent
        },
        {
            path: 'register',
            component: EventRegisterComponent
        }
    ],
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EventRoutingModule { }
